<template>
  <main class="index">
    <relatedUpdates v-if="docs.updates" :docs="docs.updates" />

    <section v-for="(genre, i) in docs.collections" :key="`index-collections-genre-${i}`">
      <relatedCollection :data="genre" />
    </section>

    <!-- <client-only>
      <adsbygoogle v-if="!user || (user.group !== 777 && user.group !== 0)" />
    </client-only> -->

    <section class="site-desc">
      <h1>Смотрим онлайн дорамы в хорошем качестве на русском</h1>
      <div class="content">
        <p>Добро пожаловать на наш уникальный ресурс, посвященный миру дорам – захватывающих телесериалов и фильмов из Азии. Если вы ищете источник высококачественного онлайн-просмотра дорам на русском языке, вы на правильном пути! Наш сайт - это библиотека, в которой вы найдете широкий выбор азиатских драм с разнообразными сюжетами, жанрами и актерским составом.</p>

        <p>Хотите окунуться в увлекательный мир дорам? У нас есть все, что вам нужно! Мы предлагаем вам уникальную возможность смотреть онлайн дорамы в высоком качестве и с субтитрами на русском языке.</p>

        <div style="margin-top: 20px;">
          <h3 style="margin: 40px 0px 20px;">
            Что такое дорамы?
          </h3>

          <p>Это яркие истории, наполненные эмоциями, приключениями и романтикой, которые моментально завоюют ваше сердце. Наши дорамы предоставляют вам возможность погрузиться в увлекательные сюжеты, познакомиться с удивительными персонажами и пережить с ними все радости и трудности.</p>
        </div>

        <div style="margin-top: 20px;">
          <h3 style="margin: 40px 0px 20px;">
            Что делает наш сайт особенным:
          </h3>

          <p>1. Огромная библиотека: Мы гордимся тем, что предлагаем одну из самых обширных коллекций дорам на русском языке. У нас есть дорамы из Южной Кореи, Японии, Китая, Тайваня и других стран, так что вы всегда найдете что-то, что заинтересует вас.</p>

          <p>2. Высокое качество: Мы заботимся о качестве просмотра, поэтому наш сайт предоставляет возможность смотреть дорамы в высоком разрешении. Вы сможете наслаждаться каждой деталью и эмоцией, как будто бы вы находитесь рядом с героями на экране.</p>

          <p>3. Русский язык: Все наши дорамы доступны на русском языке, что делает их доступными и понятными для широкой аудитории. Вы не будете тратить время на поиск субтитров или переводов - просто нажмите "воспроизвести" и наслаждайтесь просмотром.</p>

          <p>4. Регулярные обновления: Мы постоянно обновляем нашу коллекцию, чтобы предоставить вам самые свежие и популярные дорамы. Вы всегда будете в курсе новых релизов и сможете первыми смотреть горячие сериалы.</p>

          <p>5. Удобство использования: Наш сайт разработан с учетом удобства пользователей. Легкая навигация и интуитивно понятный интерфейс позволят вам быстро найти и начать просмотр интересующей дорамы.</p>

          <p>6. Сообщество фанатов: Мы собрали вокруг себя сообщество единомышленников, которые разделяют вашу страсть к азиатским дорамам. Вы можете обсуждать любимые сериалы, делиться впечатлениями и находить новых друзей, которые разделяют вашу страсть.</p>
        </div>
        
        <p>Не упустите возможность погрузиться в захватывающий мир дорам вместе с нами. Присоединяйтесь к нам и начните смотреть онлайн дорамы в хорошем качестве на русском! Начните просмотр прямо сейчас и позвольте себе испытать широкий спектр эмоций – от смеха до слез, от волнения до вдохновения. Дорамы – это искусство рассказывать истории, и мы гордимся тем, что можем предоставить вам доступ к этому искусству в лучшем качестве на русском языке. Спасибо, что выбрали нас как свой источник онлайн-развлечений. Приятного просмотра!</p>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    relatedUpdates: () => import('~/components/relatedUpdates.vue'),
    relatedCollection: () => import('~/components/relatedCollection.vue')
  },
  computed: mapState(['user', 'error']),
  data() {
    return {
      loading: false
    }
  },
  async asyncData({ $axios, query, redirect }) {
    // if (Object.keys(query).length) return redirect(`/`)

    const { data } = await $axios.get('/api/indexdata')
    return {
      docs: data
    }
  },
  mounted() {
    const updateBlocks = document.querySelectorAll('.slider')
    if (updateBlocks) {
      updateBlocks.forEach(block => {
        block.addEventListener('scroll', this.lazyLoadImgs)
      })
    }
    setTimeout(() => this.lazyLoadImgs(), 300)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.lazyLoadImgs)
  }
}
</script>

<style lang="stylus">
COLOR_YELLOW = #fad200
COLOR_LYELLOW = #fede55
COLOR_ORANGE = #ff5722
COLOR_LORANGE = #ffc107
COLOR_DARKBLUE = #3F51B5
COLOR_BLUE = #03a9f4
COLOR_PURPLE = #673AB7
COLOR_LPURPLE = #b392ef
COLOR_DARKPURPLE = #331d5b
COLOR_PINK = #e91e63

.index
  .site-desc .content
    margin-top 10px
    p
      margin-bottom 15px
    h2
      margin 40px 0 15px
  .related
    header
      margin-bottom 5px
      h2
        display inline-block
      a
        font-size 2.8rem
    &.released
      .days
        margin 20px 0 30px
        span
          display inline-block
          padding 3px 12px
          cursor pointer
          margin 5px
          border 1px solid transparent
          transition border .2s ease
          &:hover
            border-bottom 1px dashed
          &.active
            background-color #fad200
            color #000
            font-weight bold
            border-radius 20px
            &:hover
              border-bottom 1px solid transparent
      ul
        list-style none
        li
          display inline-block
          position relative
          margin 10px
          padding-right 15px
          transition background-color .2s ease
          span
            background-color rgba(255 255 255 .7)
            padding 3px 10px
            border-radius 4px
          a
            position absolute
            top 0
            left 0
            width 100%
            height 100%
            border none
          .poster
            display inline-block
            position relative
            width 50px
            height 50px
            vertical-align middle
            border-radius 50%
            margin-right 5px
            overflow hidden
            img
              width 100%
          &:hover
            background-color rgba(255 255 255 1)
            border-radius 50px
    &.updates
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 40px 10px
      ul
        list-style none
        height 400px
        overflow-y auto
        margin-top 20px
        padding 7px
        -webkit-overflow-scrolling touch
        &::-webkit-scrollbar-track
          width 7px
          background #100e19
        &::-webkit-scrollbar
          width 7px
        &::-webkit-scrollbar-thumb
          background #100e19
        &:hover::-webkit-scrollbar-thumb
          background #312b45
        li
          position relative
          margin-bottom 12px
          padding-right 10px
          border 1px solid #312b45
          border-radius 5px
          transition all .2s ease
          &:hover
            transform translateY(-2px)
            box-shadow 0 0 10px 0 rgba(0 0 0 .1)
          a
            border none
            position absolute
            left 0
            top 0
            width 100%
            height 100%
          .body
            display grid
            grid-template-columns 50px auto
            grid-gap 0 10px
            align-items center
            height 71px
            text-align center
            strong
              display block
              margin-bottom 5px
              padding 4px 10px
              font-size 1.6rem
              color #fff
              border-radius 5px
            span
              display block
              font-size 1.4rem
            .poster
              display inline-block
              margin-right 10px
              width 50px
              height 71px
              vertical-align middle
              img
                width 100%
                min-height 100%
                object-fit cover
                border-radius 5px 0 0 5px
              sup
                position absolute
                padding 5px
                top -5px
                left -5px
                color #fff
                font-size 1.2rem
                background-color #ea0042
                border-radius 5px
</style>
